import { Text } from '@components/Text';
import { CONTACT_HELP } from '@utils/constants';

import { PhoneIcon, ContactBox, Info, EmailIcon } from './styles';

export const Content = () => {
  const infos = [
    {
      icon: <PhoneIcon />,
      value: CONTACT_HELP.phone,
    },
    {
      icon: <EmailIcon />,
      value: CONTACT_HELP.email,
    },
  ];

  return (
    <>
      <Text marginB="md" size="h2" align="center">
        Need help?
      </Text>
      <Text size="large" color="gray" align="center">
        We&apos;re here for you.
      </Text>
      <Text size="large" color="gray" align="center">
        Call us and we will help you out.
      </Text>
      <ContactBox>
        <Text size="h4" marginB="md">
          Crave HQ
        </Text>
        {infos.map((info) => (
          <Info key={info.value}>
            {info.icon}
            <Text size="regular">{info.value}</Text>
          </Info>
        ))}
      </ContactBox>
    </>
  );
};

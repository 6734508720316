import { Modal } from 'antd';
import styled from 'styled-components';

import { theme } from '@theme';

const { color, border, padding, margin, borderRadius } = theme;

export const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 300px;
  border: ${border.secondary};
  border-radius: ${borderRadius.md};
  padding: ${padding.lg} ${padding.xl};
  margin-top: ${margin.lg};
  margin-bottom: 0;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: ${margin.sm};
  }
`;

export const EmailIcon = styled.div`
  width: 24px;
  height: 25px;
  background-color: ${color.black};
  -webkit-mask: url('/icons/email.svg') no-repeat center;
  mask: url('/icons/email.svg') no-repeat center;
  margin-right: ${margin.md};
`;

export const PhoneIcon = styled.div`
  width: 24px;
  height: 25px;
  background-color: ${color.black};
  -webkit-mask: url('/icons/phone.svg') no-repeat center;
  mask: url('/icons/phone.svg') no-repeat center;
  margin-right: ${margin.md};
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-top: ${padding.xxl};
    padding-bottom: ${padding.xxl};
  }
`;

import { ReactNode } from 'react';

import { PageTitle } from '@lib/PageTitle';

interface IPublicLayoutProps {
  pageTitle: string;
  children: ReactNode;
}

export const PublicLayout = ({ children, pageTitle }: IPublicLayoutProps) => (
  <>
    <PageTitle title={pageTitle} />
    {children}
  </>
);

import { LandingPage } from '@containers/LandingPage';
import { PublicLayout } from '@containers/PublicLayout';
import { ROUTES } from '@utils/pages';

const Index = () => (
  <PublicLayout pageTitle={ROUTES.LANDING_PAGE.title}>
    <LandingPage />
  </PublicLayout>
);

export default Index;
